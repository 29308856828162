import React from 'react'
import BEMHelper from 'react-bem-helper'
import Nav from '../../components/Nav'
import './index.scss'

const bem = new BEMHelper('projects-page')

const ProjectsPage = ({ children, pathname }) => {
  const buildNavLinks = () => {
    const linkLeft = {
      to: '/projects/exhibitions',
      text: '01 Exhibitions',
      modifier: pathname === '/projects/exhibitions' ? 'italic' : '',
    }

    const linkCenter = {
      to: '/projects/workshops',
      text: '02 Workshops',
      modifier: pathname === '/projects/workshops' ? 'italic' : '',
    }

    const linkRight = {
      to: '/projects/publications',
      text: '03 Publications',
      modifier: pathname === '/projects/publications' ? 'italic' : '',
    }

    return [linkLeft, linkCenter, linkRight]
  }
  return (
    <div {...bem()}>
      <Nav links={buildNavLinks()} type="projects"></Nav>
      <div {...bem('content')}>
        Coming soon. Stay tuned.
        {children}
      </div>
    </div>
  )
}

export default ProjectsPage
